export default class Share {
  constructor(popup, trigger, close) {
    this.popup = document.querySelector(popup);
    this.triggers = [].slice.call(document.querySelectorAll(trigger));
    this.close = this.popup.querySelector(close);
    this.facebookButton = this.popup.querySelector('[data-social=facebook]');
    this.twitterButton = this.popup.querySelector('[data-social=twitter]');
    this.linkedinButton = this.popup.querySelector('[data-social=linkedin]');
    this.copyInput = this.popup.querySelector('.js-copy-input');
    this.copyBtn = this.popup.querySelector('.js-copy-btn');
  }
  init() {
    document.addEventListener('social-share', (e) => {
      this.facebookButton ? this.facebookButton.setAttribute('href', e.detail.facebook) : null
      this.twitterButton ? this.twitterButton.setAttribute('href', e.detail.twitter) : null
      this.linkedinButton ? this.linkedinButton.setAttribute('href', e.detail.linkedin) : null
      this.copyInput.innerText = e.detail.url
      this.popup.classList.add('show')
    })
    this.copyBtn.addEventListener('click', this.copy)
    this.triggers.forEach(trigger => {
      trigger.addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()
        // const url = trigger.dataset.url
        this.getTriggerAttr(trigger)
        trigger.dispatchEvent(new CustomEvent('social-share', {
          bubbles: true,
          detail: {
            facebook: this.facebook,
            twitter: this.twitter,
            linkedin: this.linkedin,
            url: trigger.dataset.url
          }
        }))
      })
    })
    this.close.addEventListener('click', this.closePopup)
    this.popup.addEventListener('click', (e) => {
      if(!e.target.closest('.social-share-modal')) {
        this.closePopup()
      }
    })
  }
  closePopup = () => {
    this.popup.classList.remove('show')
  }
  getTriggerAttr = (trigger) => {
    this.facebook = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(trigger.dataset.url)}`
    // this.facebook = {
    //   shareUrl: 'https://www.facebook.com/sharer/sharer.php',
    //   params: {
    //     url: trigger.dataset.url
    //   }
    // }
    this.twitter = `https://twitter.com/intent/tweet?url=${encodeURIComponent(trigger.dataset.url)}${trigger.dataset.title ? '&text=' + encodeURIComponent(trigger.dataset.title) : ''}`
    // this.twitter = {
    //   shareUrl: 'https://twitter.com/intent/tweet/',
    //   params: {
    //     url: trigger.dataset.url,
    //     text: trigger.dataset.title
    //   }
    // }
    this.linkedin = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(trigger.dataset.url)}&mini=true`
    // this.linkedin = {
    //   shareUrl: 'https://www.linkedin.com/shareArticle',
    //   params: {
    //     url: trigger.dataset.url,
    //     mini: true
    //   }
    // }
    this.url = trigger.dataset.url
  }
  copy() {
    const el = document.createElement('textarea');
    el.value = this.url ? this.url : document.querySelector('.js-copy-input').innerText;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
}